<script>
import AnalyticsServiceApi from "@/service/api/analytics";

export default {
  data() {
    return {
      aggreData: [
        {
          title: "Number of Sales",
          icon: "ri-stack-line",
          value: 0,
        },
        {
          title: "Revenue",
          icon: "ri-store-2-line",
          value: 0,
        },
        {
          title: "Profit",
          icon: "ri-briefcase-4-line",
          value: 0,
        },
      ],
    };
  },
  methods: {
    loadAggregationData() {
      AnalyticsServiceApi.getAggregatedAnalytics().then((response) => {
        this.aggreData[0].value = response.data.total_sales;
        this.aggreData[1].value = "AED "+response.data.total_revenue;
        this.aggreData[2].value = "AED "+response.data.total_profit;
      });
    },
  },
  mounted() {
    this.loadAggregationData();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in aggreData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h5 class="mb-0">{{ data.value }}</h5>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`" style="color:#da1d34"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
