<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "./widget";
import RevenueAnalytics from "./revenue";
import SalesAnalytics from "./sales-analytics";
import EarningReport from "./earning";
import TopProducts from "./top-products";
import RecentTrade from "./recent-trade";
import RecentOrder from "./recent-order";
import OrderDetails from "./order-details";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    RevenueAnalytics,
    SalesAnalytics,
    EarningReport,
    TopProducts,
    RecentTrade,
    RecentOrder,
    OrderDetails,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Sheesh",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <Stat />
        <RevenueAnalytics />
      </div>
      <div class="col-xl-4">
        <SalesAnalytics />
        <EarningReport />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <RecentOrder />
      </div>
      <div class="col-lg-6">
        <RecentTrade />
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <TopProducts />
      </div>
      <div class="col-md-4">
        <OrderDetails />
      </div>
    </div>
  </Layout>
</template>
