export default{
    buy:{
        "0":{
            label:"Pending Shipment",
            status:"pending",
            color:"#4aa3ff",
        },
        "1":{
            label:"En-route to Sheesh",
            status:"processing",
            color:"#5664d2"
        },
        "2":{
            label:"Received& Authentication Process",
            status:"processing",
            color:"#41b883"
        },
        "3":{
            label:"En-route to buyers",
            status:"processing",
            color:"#fcb92c"
        },
        "4":{
            label:"Received by buyer",
            status:"completed",
            color:"#ff3d60;"
        },
        "5":{
            label:"Item Rejected",
            status:"failed",
            color:"#ff3d60;"
        },
        "6":{
            label:"Transaction Cancelled",
            status:"cancelled",
            color:"#ff3d60;"
        },
        "7":{
            label:"Payment Failed",
            status:"error",
            color:"#ff3d60;"
        }
     
    },
    sell:{
        "0":{
            label:"Pending Shipment",
            status:"pending",
            color:"#4aa3ff",
        },
        "1":{
            label:"En-route to Sheesh",
            status:"pending",
            color:"#5664d2"
        },
        "2":{
            label:"Received& Authentication Process",
            status:"processing",
            color:"#41b883"
        },
        "3":{
            label:"En-route to buyers",
            status:"processing",
            color:"#fcb92c"
        },
        "4":{
            label:"Received by buyer",
            status:"completed",
            color:"#ff3d60;"
        },
        "5":{
            label:"Item Rejected",
            status:"failed",
            color:"#ff3d60;"
        },
        "6":{
            label:"Transaction Cancelled",
            status:"cancelled",
            color:"#ff3d60;"
        },
        "7":{
            label:"Payment Failed",
            status:"error",
            color:"#ff3d60;"
        }

    },
    trade:{
        "0" : {
            label:'No receiver has accepted',
            status:"pending",
            color:"#4aa3ff",
        },
        "1" : {
            label:'Pending shipment to Sheesh',
            status:"processing",
            color:"#4aa3ff",
        },
        "2" : {
            label:'En-route to Sheesh',
            status:"processing",
            color:"#5664d2"
        },
        "3" :{
            label: 'Received & awaiting authentication by Sheesh',
            status:"processing",
            color:"#41b883"
        },
        "4" : {
            label:'En-route to user',
            status:"processing",
            color:"#fcb92c"
        },
        "5" :{
            label: 'Item received by user',
            status:"completed",
            color:"#ff3d60;"
        },
        "6" :{
            label: 'Item rejected',
            status:"error",
            color:"#ff3d60;"
        },
        "7" :{
            label:  'Trade cancelled',
            status:"error",
            color:"#ff3d60;"
        },
        "8" : {
            label:'Trade payment failed',
            status:"error",
            color:"#ff3d60;"
        },
    },

}