<script>
import AnalyticsServiceApi  from "@/service/api/analytics"

export default {
  data() {
    return {
      earning: {
        weekly_earnings : 0,
        monthly_earnings:0,
      },
    };
  },
  methods:{
    loadData(){
      AnalyticsServiceApi.getTotalEarnings().then((response)=>{
        this.earning.weekly_earnings = response.data.weekly_earnings ?response.data.weekly_earnings : 0;
        this.earning.monthly_earnings= response.data.monthly_earnings ? response.data.monthly_earnings :0;
      })
    }
  },
  mounted(){
    this.loadData();
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <b-dropdown
        right
        toggle-class="arrow-none card-drop"
        class="float-right"
        variant="white"
      >
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>

        <b-dropdown-item>Revenue Report</b-dropdown-item>
        <!-- item-->
        <b-dropdown-item>Profit</b-dropdown-item>
      </b-dropdown>

      <h4 class="card-title mb-4">Earning Reports</h4>
      <div class="text-center">
        <div class="row">
          <div class="col-sm-6">
            <div class="sales-card">
            
              <p class="text-truncate mb-2">Weekly Earnings</p>
              <h6 class="mb-0">AED {{earning.weekly_earnings}}</h6>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="mt-5 mt-sm-0 sales-card">
           

              <p class="text-truncate mb-2">Monthly Earnings</p>
              <h6 class="mb-0">AED {{earning.monthly_earnings}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.sales-card{
  background-color:#da1d34;
  color:#fff !important;
  border-radius:12px;
  padding:10px 20px;
}
.sales-card h6{
  color:white;
  font-weight:900;
  font-size:18px;
}
</style>