<script>
import AnalyticsServiceApi from "@/service/api/analytics"  
/**
 * Revenue Analytics component
 */
export default {
  data() {
    return {
      revenueData:null,
      loading:false,
      series: [
        {
          name: `Year ${this.moment().format("YYYY")}` ,
          type: "column",
          data: [],
        },
        {
          name: `Year ${this.moment().format("YYYY")-1}` ,
          type: "line",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 3],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#da1d34", "#1cbb8c"],
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
     
      },
      months:[
          'january',
          'february',
          'march',
          'april',
          'may',
          'june',
          'july',
          'august',
          'september',
          'october',
          'november',
          'december',
      ]
    };
  },
  methods:{
    loadAnalyticData(){
      this.loading = true;
      AnalyticsServiceApi.getRevenuesAnalytics().then((response)=>{
        this.revenueData = response.data;
        if(this.revenueData){
          this.series[0].data= []
          this.series[1].data= []
          this.months.map((item)=>{
            this.series[0].data.push(this.revenueData.current_year[item])
            this.series[1].data.push(this.revenueData.previous_year[item])
         })
        }
      }
      ).finally(()=>{
        this.loading = false;
      })
      
    }
    
    
  },
  mounted(){
    this.loadAnalyticData()
  },
  computed:{
      percentageIncrease(){
        if(this.revenueData && this.revenueData.previous_year.previous_year_revenue){
          return (
            ((this.revenueData.current_year.current_year_revenue -
            this.revenueData.previous_year.previous_year_revenue)/
            this.revenueData.previous_year.previous_year_revenue)*
            100)
          
        }
   
        return 0; 
      }
  }
};
</script>

<template>
  <div class="card">
    <div v-if="!loading">
     <div  v-if="revenueData">
       <div class="card-body" style="margin-bottom:36px;">
    
        <h4 class="card-title mb-4">Monthly Revenue Analytics</h4>
        <div>
          <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
          <apexchart
            class="apex-charts"
            height="280"
            dir="ltr"
            :series="series"
            :options="chartOptions"
          ></apexchart>
        </div>
      </div>
      <div class="card-body border-top text-center">
        <div class="row">
          <div class="col-sm-4">
            <div class="mt-4 mt-sm-0">
              <p class="mb-2 text-muted text-truncate">
                This Month
              </p>
              <div>
                <h6 class="mb-0 mr-2">AED <span v-if="revenueData.currentMonth">{{revenueData.currentMonth}}</span><span v-else>0</span> </h6>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="mt-4 mt-sm-0">
              <p class="mb-2 text-muted text-truncate">
                <i class="mdi mdi-circle  font-size-10 mr-1" style="color:#da1d34"></i> This
                Year :
              </p>
              <div>
                <h6 class="mb-0 mr-2">AED {{revenueData.current_year.current_year_revenue}}</h6>
                <div class="text-success mt-2">
                  <i class="mdi mdi-menu-up font-size-14"></i>{{percentageIncrease}} %
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="mt-4 mt-sm-0">
              <p class="mb-2 text-muted text-truncate">
                <i class="mdi mdi-circle text-success font-size-10 mr-1"></i>
                Previous Year
              </p>
              <div>
                <h6 class="mb-0">AED {{revenueData.previous_year.previous_year_revenue}}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
      <div v-else class="pt-5 pb-5" style="text-align:center">
        No Sufficient Data!
      </div>
    </div>
    <div v-else>
        <b-spinner
          style="display:block;margin:auto;"
          variant="danger"
          role="status"
          class="mt-5 mb-5"
        ></b-spinner>
    </div> 
  </div>
</template>
