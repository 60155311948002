<script>
import AnalyticsServiceApi from "@/service/api/analytics";
import Progress from "@/service/transactionType";
export default {
  data() {
    return {
      trade: Progress.trade,
      tradeData: [],
      loading: false,
    };
  },
  methods: {
    loadRecentTrades() {
      this.loading = true;
      AnalyticsServiceApi.getRecentTrades()
        .then((respose) => {
          this.tradeData = respose.data.trades;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLeastStatus(arg1, arg2) {
      return arg1 <= arg2 ? arg1 : arg2;
    },
  },
  mounted() {
    this.loadRecentTrades();
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <button
        @click="loadRecentTrades"
        class="btn btn-sm btn-secondary float-right"
      >
        <i class="ri-refresh-line"></i>
      </button>
      <span class="card-title mb-4">Recent Trade</span>
      <div v-if="loading">
        <b-spinner
          style="display:block;margin:auto;font-size:18px;margin-top:48px;margin-bottom:48px"
          variant="danger"
        ></b-spinner>
      </div>

      <div
        data-simplebar
        style="max-height: 330px;"
        v-if="!loading && tradeData.length"
      >
        <ul class="list-unstyled activity-wid">
          <li
            class="activity-list"
            v-for="(data, index) in tradeData"
            :key="index"
          >
            <div class="activity-icon avatar-xs">
              <span
                class="avatar-title bg-soft-primary text-primary rounded-circle"
              >
                <i>{{
                  `${trade[
                    getLeastStatus(data.giver_progress, data.receiver_progress)
                  ].label.charAt(0)}`
                }}</i>
              </span>
            </div>
            <div>
              <div>
                <h5 class="font-size-13">
                  {{ data.date }}
                  <small class="text-muted">
                    {{
                      moment(data.updated_at).format("MMMM Do YYYY, h:mm:ss a")
                    }}</small
                  >
                </h5>
              </div>

              <div>
                <p class="text-muted mb-0">Trade #{{ data.id }}</p>
                <div class="mb-0">
                  <div class="row mt-1">
                    <div class="col-6">
                      <p class="text-muted mb-1">Giver Status</p>
                      <span
                        :style="`color:${trade[data.giver_progress].color}`"
                      >
                        {{ trade[data.giver_progress].label }}
                      </span>
                    </div>
                    <div class="col-6">
                      <p class="text-muted mb-1">Receiver Status</p>
                      <span
                        :style="`color:${trade[data.receiver_progress].color}`"
                      >
                        {{ trade[data.receiver_progress].label }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-if="!loading && tradeData.length < 1"
        style="font-size:18px;text-align:center;margin:48px"
      >
        No Trades
      </div>
    </div>
  </div>
</template>
