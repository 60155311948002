import axios from "@/plugins/axios";
import authHeader from '../auth-header'
import ConfigService from "../config"


const API_URL = ConfigService.base_url;

class AnalyticsServiceApi {
    getRecentOrder() {
          return this.initiateAPICall({ 
            action:"orders"
          })
      }
      getTotalEarnings() {
        return this.initiateAPICall({ 
          action:"earnings"
        })
    }
      getRecentTrades() {
          return this.initiateAPICall({ 
            action:"trades"
          })
      }
      getTrendingProduct() {
          return this.initiateAPICall({ 
            action:"trending"
          })
      }
      getAggregatedAnalytics() {
        return this.initiateAPICall({ 
          action:"total"
        })
       
      }
      getAggregatedSalePercentage(month) {
          return this.initiateAPICall({ 
            action:"sales",
            month:month
          })
      }
      getRevenuesAnalytics() {
        return this.initiateAPICall({ 
          action:"revenues",
        })
    }
        getOrdersProgress() {
          return this.initiateAPICall({ 
            action:"orders_progress",
          })
      }
      initiateAPICall(params){
        return axios
          .get(API_URL + 'admin/analytics', {
            headers:  authHeader(),
            params:params,
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
}



export default new AnalyticsServiceApi()


