<script>
import AnalyticsServiceApi from "@/service/api/analytics";

export default {
  data() {
    return {
      progress: null,
      loading: false,
    };
  },
  methods: {
    orderDetailSelect(item) {
      switch (item) {
        case "pending":
          this.$router.push({ name: "Orders", params: { tab: 0 } });
          break;
        case "processing":
          this.$router.push({ name: "Orders", params: { tab: 2 } });
          break;
        case "completed":
          this.$router.push({ name: "Orders", params: { tab: 4 } });
          break;
        case "rejected":
          this.$router.push({ name: "Orders", params: { tab: 5 } });
          break;
        case "cancelled":
          this.$router.push({ name: "Orders", params: { tab: 6 } });
          break;
      }
    },
    loadOrderProgress() {
      this.loading = true;
      AnalyticsServiceApi.getOrdersProgress()
        .then((response) => {
          this.progress = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loadOrderProgress();
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div v-if="!loading">
        <div v-if="progress">
          <h4 class="card-title mb-4">Orders</h4>
          <div class="ord-card" v-on:click="orderDetailSelect('pending')">
            Pending
            <div class="float-right order-badge">
              {{ progress.pending }}
            </div>
          </div>
          <div class="ord-card" v-on:click="orderDetailSelect('processing')">
            Processing
            <div
              class="float-right order-badge"
              style="background-color:#5757b7"
            >
              {{ progress.processing }}
            </div>
          </div>
          <div class="ord-card" v-on:click="orderDetailSelect('completed')">
            Completed
            <div class="float-right order-badge" style="background-color:green">
              {{ progress.completed }}
            </div>
          </div>
          <div class="ord-card" v-on:click="orderDetailSelect('rejected')">
            Rejected
            <div class="float-right order-badge" style="background-color:red">
              {{ progress.rejected }}
            </div>
          </div>
          <div class="ord-card" v-on:click="orderDetailSelect('cancelled')">
            Cancelled
            <div
              class="float-right order-badge"
              style="background-color:orange"
            >
              {{ progress.cancelled }}
            </div>
          </div>
        </div>
        <div v-else style="text-align:center">
         No Orders
        </div>
      </div>

      <div v-else>
        <b-spinner
          style="display:block;margin:auto;"
          variant="danger"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ord-card {
  background-color: #f1f1f1;
  margin: 10px;
  border-radius: 10px;
  padding: 7px 24px;
}
.ord-card:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.order-badge {
  background-color: gray;
  padding: 2px 14px;
  border-radius: 10px;
  color: #fff;
}
</style>
