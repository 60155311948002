<script>
import AnalyticsServiceApi from "@/service/api/analytics";
export default {
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  methods: {
    loadTopProducts() {
      this.loading = true;
      AnalyticsServiceApi.getTrendingProduct().then((response) => {
        this.data = response.data.data;
      }).finally(()=>{
        this.loading = false;
      });
    },
  },
  mounted() {
    this.loadTopProducts();
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <button
        @click="loadTopProducts"
        class="btn btn-sm btn-secondary float-right"
      >
        <i class="ri-refresh-line"></i>
      </button>
      <h4 class="card-title mb-b">Top Trending Products</h4>
      <div v-if="loading">
        <b-spinner
          style="display:block;margin:auto;font-size:18px;margin-top:48px;margin-bottom:48px"
          variant="danger"
        ></b-spinner>
      </div>

      <div v-if="!loading && data.length">
        <div class="mt-4" style="height:210px;overflow-y:scroll">
          <table class="table table-responsive ">
            <thead>
              <th>Product</th>
              <th>Last Month Sale</th>
              <th>Last Week Sale</th>
              <th>Total Sales</th>
            </thead>
            <tbody>
              <tr v-for="item in data" :key="item.id">
                <td>
                  <div class="row">
                    <div class="col-2">
                      <div class="avatar-xs" style="display:inline-block">
                        <div
                          class="avatar-title bg-light"
                          style="display:inline-block"
                        >
                          <img :src="item.image" alt height="20" />
                        </div>
                      </div>
                    </div>
                    <div class="col-10">
                        <span class="font-size-14 mb-0 ml-1">{{ item.name }}</span>
                    </div>
                  </div>
                </td>

                <td>
                  <p class="text-muted mb-0">AED {{ item.lastMonth }}</p>
                </td>
                <td>
                  <p class="text-muted mb-0">AED {{ item.lastWeek }}</p>
                </td>
                <td>
                  <p class="text-muted mb-0">AED {{ item.totalSales }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        v-if="!loading && data.length < 1"
        style="font-size:18px;text-align:center;margin:48px"
      >
        No Trending Product
      </div>
    </div>
  </div>
</template>
