export default [
        {
            "abbre": "Jan",
            "name": "January"
        },
        {
            "abbre": "Feb",
            "name": "February"
        },
        {
            "abbre": "Mar",
            "name": "March"
        },
        {
            "abbre": "Apr",
            "name": "April"
        },
        {
            "abbre": "May",
            "name": "May"
        },
        {
            "abbre": "Jun",
            "name": "June"
        },
        {
            "abbre": "Jul",
            "name": "July"
        },
        {
            "abbre": "Aug",
            "name": "August"
        },
        {
            "abbre": "Sep",
            "name": "September"
        },
        {
            "abbre": "Oct",
            "name": "October"
        },
        {
            "abbre": "Nov",
            "name": "November"
        },
        {
            "abbre": "Dec",
            "name": "December"
        }
    ]

