<script>
/**
 * Sales Analytics component
 */
import AnalyticsServiceApi from "@/service/api/analytics";
import Months from "@/service/months";
export default {
  data() {
    return {
      data: null,
      loading: false,
      monthSelected: this.moment().format("MMMM"),
      series: [],
      chartOptions: {
        chart: {
          height: 230,
          type: "donut",
        },
        labels: [],
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#da1d34", "#1cbb8c", "#eeb902"],
      },
    };
  },
  mounted() {
    this.loadSalesAnalytics();
  },
  computed: {
    months() {
      return Months.map((val) => {
        return {
          value: val.name,
          text: val.abbre,
        };
      });
    },
    isThereData() {
      return this.data ? !this.isDataEmpty(this.data) : false;
    },
  },
  methods: {
    isDataEmpty(data) {
      let flag = true;
      for (const item in data) {
        if (data[item] > 0) {
          flag = false;
          return flag;
        }
      }

      return flag;
    },
    loadSalesAnalytics(month = this.monthSelected) {
      this.loading = true;
      this.data = null;
      AnalyticsServiceApi.getAggregatedSalePercentage(month)
        .then((response) => {
          if (response && response.data) {
            this.parsDataToGraph(response.data);
            this.data = response.data;
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          this.loading = false;
        });
    },
    parsDataToGraph(data) {
      this.series = [];
      this.chartOptions.labels = [];
      Object.entries(data).map((val) => {
        if (val[0] !== "month_sales") {
          this.series.push(val[1]);
          this.chartOptions.labels.push(val[0]);
        }
      });
    },
    monthChanged(val) {
      this.loadSalesAnalytics(val);
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="float-right">
        <b-form-select
          v-model="monthSelected"
          v-on:change="monthChanged"
          size="sm"
          :options="months"
        ></b-form-select>
      </div>
      <h4 class="card-title mb-4">Sales Analytics</h4>
      <div v-if="loading">
        <b-spinner
          style="display:block;margin:auto;font-size:18px;margin-top:48px;margin-bottom:48px"
          variant="danger"
        ></b-spinner>
      </div>
      <div v-else>
        <div v-if="isThereData">
          <div id="donut-chart" class="apex-charts"></div>
          <apexchart
            class="apex-charts"
            height="230"
            dir="ltr"
            :series="series"
            :options="chartOptions"
          ></apexchart>
          <div class="row">
            <div class="col-4">
              <div class="text-center mt-4">
                <p class="mb-2">
                  <i class="mdi mdi-circle font-size-10 mr-1" style="color:#da1d34"></i>
                  <span style="display:block">
                    Sneakers
                  </span>
                </p>
                <h5>
                  <span v-if="data">{{ (data.sneaker_sales * 100).toFixed(2) }}%</span>
                </h5>
              </div>
            </div>
            <div class="col-4">
              <div class="text-center mt-4">
                <p class="mb-2">
                  <i class="mdi mdi-circle text-success font-size-10 mr-1"></i>
                  <span style="display:block">
                    Streetwear
                  </span>
                </p>
                <h5>
                  <span v-if="data">{{ (data.streetwear_sales * 100).toFixed(2) }}%</span>
                </h5>
              </div>
            </div>
            <div class="col-4">
              <div class="text-center mt-4">
                <p class="mb-2">
                  <i class="mdi mdi-circle text-warning font-size-10 mr-1"></i>
                  <span style="margin-left:-10px;display:block">
                    Collectibles
                  </span>
                </p>
                <h5>
                  <span v-if="data">{{ (data.collectible_sales * 100).toFixed(2) }}%</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          style="text-align:center;margin-top:50px;margin-bottom:50px"
        >
          No Data
        </div>
      </div>
    </div>
  </div>
</template>
